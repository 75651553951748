import { MutableRefObject, useContext, useMemo, useRef } from 'react';

import { StoreContext } from '@keaze/web/store';
import { FormField, FormFields } from './form.types';

type UseSignInFormExpected = {
  initialValues: FormFields;
  prevFormValues: MutableRefObject<FormFields>;
};

type UseSignInForm = () => UseSignInFormExpected;

export const useSignInForm: UseSignInForm = () => {
  const {
    state: {
      profile: { profile },
    },
  } = useContext(StoreContext);

  const initialValues: FormFields = useMemo(
    () => ({
      [FormField.Email]: profile.email ?? '',
    }),
    [profile]
  );

  const prevFormValues = useRef<FormFields>(initialValues);

  return {
    prevFormValues,
    initialValues,
  };
};
