import { FC, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { fade, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import {
  AuthDialog as UIAuthDialog,
  Header,
  IconButtonWrapper,
  CloseIcon,
  Content,
} from './dialog.styles';
import { AuthDialogProps } from './dialog.types';

const useStyles = makeStyles(({ colors, breakpoints }) => ({
  paper: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 0,
    borderRadius: 0,
    [breakpoints.up('sm')]: {
      width: 'auto',
      maxWidth: 'calc(100% - 32px)',
      maxHeight: 'calc(100% - 32px)',
    },
  },
  backdrop: {
    backgroundColor: fade(colors.blues.blues4, 0.85),
  },
}));

export const AuthDialog: FC<AuthDialogProps> = memo(
  ({
    children,
    title,
    width,
    fullHeightMobile = false,
    open,
    onClose,
    onExited,
  }) => {
    const classes = useStyles();

    return (
      <Dialog
        PaperProps={{
          className: classes.paper,
        }}
        BackdropProps={{
          className: classes.backdrop,
        }}
        maxWidth={false}
        closeAfterTransition
        open={open}
        scroll="body"
        onExited={onExited}
      >
        <UIAuthDialog
          $width={width}
          $fullHeightMobile={fullHeightMobile}
          container
          direction="column"
          wrap="nowrap"
        >
          <Header>
            {title && <Typography variant="h2">{title}</Typography>}
            <IconButtonWrapper>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </IconButtonWrapper>
          </Header>
          <Content container direction="column">
            {children}
          </Content>
        </UIAuthDialog>
      </Dialog>
    );
  }
);
