import { FC, memo } from 'react';

import { useAuth } from './auth.hook';
import { AuthDialog } from './dialog';
import { SignIn } from './signIn';
import { CreateAccount } from './createAccount';
import { AuthError } from './error';

interface TemplateProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onExited?: () => void;
}

const Template: FC<TemplateProps> = ({
  children,
  title,
  open,
  onClose,
  onExited,
}) => (
  <AuthDialog title={title} open={open} onClose={onClose} onExited={onExited}>
    {children}
  </AuthDialog>
);

export const Auth: FC = memo(() => {
  const {
    isSignInDialog,
    isAuthFailed,
    openAuthDialog,
    resetSignInDialog,
    onAuthErrorExited,
    onClose,
  } = useAuth();

  if (isAuthFailed) {
    return (
      <Template
        title="Couldn’t sign you in"
        open={openAuthDialog}
        onClose={onClose}
        onExited={onAuthErrorExited}
      >
        <AuthError onClose={onClose} />
      </Template>
    );
  }

  if (isSignInDialog) {
    return (
      <Template
        title={'Sign in'}
        open={openAuthDialog}
        onClose={onClose}
        onExited={resetSignInDialog}
      >
        <SignIn />
      </Template>
    );
  }

  return (
    <Template title="Register" open={openAuthDialog} onClose={onClose}>
      <CreateAccount />
    </Template>
  );
});
