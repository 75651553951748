import React, { FC, memo } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormRenderProps } from 'react-final-form';

import { Groups } from '@keaze/web/utils/form/groups';
import { FormFields } from '../form.types';
import { Button, Container } from './renderForm.styles';
import { useRenderForm } from './renderForm.hook';

export const RenderForm: FC<
  FormRenderProps<FormFields, Partial<FormFields>>
> = memo(({ handleSubmit }) => {
  const { fieldsGroups } = useRenderForm();

  return (
    <form onSubmit={handleSubmit}>
      <Container container>
        <Grid item xs={12}>
          <Groups fieldsGroups={fieldsGroups} />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" color="secondary" variant="contained" fullWidth>
            Sign in
          </Button>
        </Grid>
      </Container>
    </form>
  );
});
