import { FC, memo, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { AuthContext } from '@keaze/web/contexts/auth';
import { Choice as UIChoice } from './choice.styles';

type Props = {
  text: string;
  buttonName: string;
};

export const Choice: FC<Props> = memo(({ text, buttonName }) => {
  const { toggleSignInDialog } = useContext(AuthContext);

  return (
    <UIChoice>
      <Typography>{text}</Typography>
      <Button variant="text" color="primary" onClick={toggleSignInDialog}>
        {buttonName}
      </Button>
    </UIChoice>
  );
});
