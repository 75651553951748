import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography, { TypographyTypeMap } from '@material-ui/core/Typography';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export const CheckEmail: OverridableComponent<
  TypographyTypeMap<unknown, 'span'>
> = styled(Typography)`
  font-weight: 700;
`;

export const Email: OverridableComponent<
  TypographyTypeMap<unknown, 'span'>
> = styled(Typography)`
  font-weight: 600;
`;

export const Icon = styled(Box)`
  margin-bottom: 5px;
`;
