import styled from 'styled-components';
import Grid, { GridProps } from '@material-ui/core/Grid';
import Typography, { TypographyTypeMap } from '@material-ui/core/Typography';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import { WithMyTheme } from '@keaze/web/theme';

interface ContentProps extends WithMyTheme {
  $marginBottom?: number;
}

export const Text: OverridableComponent<
  TypographyTypeMap<unknown, 'span'>
> = styled(Typography)`
  text-align: center;
`;

export const Content = styled(Grid)<GridProps & ContentProps>`
  ${({ $marginBottom = 10 }: ContentProps) => `
    margin-bottom: ${$marginBottom}px;
  `}
`;
