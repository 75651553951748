import styled from 'styled-components';
import Grid, { GridProps } from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

import { WithMyTheme } from '@keaze/web/theme';

interface AuthData extends WithMyTheme {
  $width?: number;
  $fullHeightMobile: boolean;
}

export const Content = styled(Grid)``;

export const AuthDialog = styled(Grid)<GridProps & AuthData>`
  ${({ theme: { breakpoints }, $width = 570, $fullHeightMobile }) => `
    position: relative;
    padding: 20px;
    height: ${$fullHeightMobile ? '100vh' : ''};

    ${Content} {
      height: 100%;
    }

    ${breakpoints.up('sm')} {
      width: ${$width}px;
      height: auto;
      padding: 30px;

      ${Content} {
        height: auto;
      }
    }
  `}
`;

export const Header = styled.header`
  ${({ theme: { breakpoints } }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 5px;

    ${breakpoints.up('sm')} {
      margin-bottom: 17px;

    }
  `}
`;

export const CloseIcon = styled(Close)`
  ${({ theme: { palette } }) => `
    fill: ${palette.primary.main};
    opacity: 0.3;
  `}
`;

export const IconButtonWrapper = styled(Box)`
  margin-left: 10px;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
`;
