import { FC, memo } from 'react';

import { AuthSuccess } from '../success';
import { Form } from './form';
import { useCreateAccount } from './createAccount.hook';
import { Choice } from '../choice';

export const CreateAccount: FC = memo(() => {
  const {
    isLoading,
    isMagicLinkSuccess,
    errors,
    onClose,
    onSubmit,
  } = useCreateAccount();

  if (isMagicLinkSuccess) {
    return (
      <AuthSuccess
        title="Check your email"
        ButtonProps={{
          title: 'Got it!',
          color: 'primary',
        }}
        onClose={onClose}
      />
    );
  }

  return (
    <>
      <Form isLoading={isLoading} errors={errors} onSubmit={onSubmit} />
      <Choice text="Already have an account?" buttonName="Sign in" />
    </>
  );
});
