import { useMemo } from 'react';

import { emailValidationError } from '@keaze/web/common/validators';
import { FieldsGroup } from '@keaze/web/utils/form';
import { FormField } from '../form.types';

type UseRenderFormExpected = {
  fieldsGroups: FieldsGroup[];
};

type UseRenderForm = () => UseRenderFormExpected;

export const useRenderForm: UseRenderForm = () => {
  const personalInformationGroup: FieldsGroup[] = useMemo(
    () => [
      {
        fields: [
          {
            label: 'Email',
            name: FormField.Email,
            type: 'email',
            validate: {
              required: true,
              valid: emailValidationError,
            },
          },
        ],
      },
    ],
    []
  );

  return {
    fieldsGroups: personalInformationGroup,
  };
};
