import { FC, memo } from 'react';

import { AuthSuccess, AuthSuccessButtonProps } from '../success';
import { useSignIn } from './signIn.hook';
import { Form } from './form';
import { Choice } from '../choice';

const DEFAULT_BUTTON_PROPS: AuthSuccessButtonProps = {
  title: 'Got it!',
  color: 'primary',
};

type Props = {
  urlAfterSignIn?: string;
  successDialogTitle?: string;
  successDialogButtonProps?: AuthSuccessButtonProps;
  onClose?: () => void;
};

export const SignIn: FC<Props> = memo(
  ({
    urlAfterSignIn,
    successDialogTitle = 'Check your email',
    successDialogButtonProps = DEFAULT_BUTTON_PROPS,
    onClose: customOnClose,
  }) => {
    const {
      isLoading,
      isMagicLinkSuccess,
      errors,
      onClose,
      onSubmit,
    } = useSignIn(urlAfterSignIn);

    if (isMagicLinkSuccess) {
      return (
        <AuthSuccess
          title={successDialogTitle}
          ButtonProps={successDialogButtonProps}
          onClose={customOnClose ?? onClose}
        />
      );
    }

    return (
      <>
        <Form isLoading={isLoading} errors={errors} onSubmit={onSubmit} />
        <Choice text="Don’t have an account?" buttonName="Register" />
      </>
    );
  }
);
