import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import MUIButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export const Button = styled(MUIButton)`
  margin-top: 10px;
`;

export const Container = styled(Grid)`
  .MuiGrid-item {
    & > div {
      margin-bottom: 20px;
    }
  }
`;

export const IWantToReceive = styled(Typography)`
  ${({ theme: { palette } }) => `
    color: ${palette.text.secondary};
    opacity: 0.75;
  `}
`;
