import { useMemo } from 'react';

import {
  emailValidationError,
  phoneValidationError,
} from '@keaze/web/common/validators';
import { Components, FieldsGroup } from '@keaze/web/utils/form';
import { useIWantToReceiveFields } from '@keaze/web/utils/form/hooks';
import { FormField } from '../form.types';
import { renderIWantToReceiveNotice } from './renderForm';
import { renderPrivacyPolicy } from '@keaze/web/utils/form/privacyPolicy';

type UseRenderFormExpected = {
  fieldsGroups: FieldsGroup[];
};

type UseRenderForm = () => UseRenderFormExpected;

export const useRenderForm: UseRenderForm = () => {
  useIWantToReceiveFields();

  const personalInformationGroup: FieldsGroup[] = useMemo(
    () => [
      {
        fields: [
          {
            label: 'First name',
            name: FormField.FirstName,
          },
          {
            label: 'Last name',
            name: FormField.LastName,
          },
          {
            label: 'Email',
            name: FormField.Email,
            type: 'email',
            validate: {
              required: true,
              valid: emailValidationError,
            },
          },
          {
            label: 'Phone number',
            name: FormField.Phone,
            type: 'tel',
            validate: {
              required: true,
              valid: phoneValidationError,
            },
          },
        ],
      },
    ],
    []
  );
  const privacyPolicyGroup: FieldsGroup[] = useMemo(
    () => [
      {
        fields: [
          {
            name: 'privacyPolicy',
            component: Components.CheckboxGroup,
            checkboxGroupData: [
              {
                label: renderPrivacyPolicy(),
                name: FormField.PrivacyPolicyAccepted,
                validate: {
                  required: true,
                  important: true,
                },
              },
            ],
          },
        ],
      },
      {
        title: 'I want to receive',
        notice: renderIWantToReceiveNotice(),
        fields: [
          {
            name: 'receive',
            component: Components.CheckboxGroup,
            checkboxGroupData: [
              {
                label: 'Alerts about other relevant properties',
                name: FormField.NewsletterConsent,
              },
              {
                label: 'Information relating to relevant news and surveys',
                name: FormField.NewsSurveysConsent,
              },
              {
                label: `Information and offers from Keaze's chosen partners`,
                name: FormField.PartnersOffersConsent,
              },
            ],
          },
        ],
      },
    ],
    []
  );

  const fieldsGroups: FieldsGroup[] = useMemo(
    () => [...personalInformationGroup, ...privacyPolicyGroup],
    [personalInformationGroup, privacyPolicyGroup]
  );

  return {
    fieldsGroups,
  };
};
