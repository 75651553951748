import { request } from '../../api';
import { Methods } from '../../api.types';
import { CUSTOMERS_QUERY } from '../customers';
import { CheckCustomerEmail } from './checkCustomerEmail.types';

const EMAIL_QUERY = 'email';

export const checkCustomerEmail: CheckCustomerEmail = async (email) =>
  await request({
    method: Methods.HEAD,
    query: `${CUSTOMERS_QUERY}/${EMAIL_QUERY}/${email}`,
  });
