import { MutableRefObject, useContext, useMemo, useRef } from 'react';

import { StoreContext } from '@keaze/web/store';
import { FormField, FormFields } from './form.types';

type UseCreateAccountFormExpected = {
  initialValues: FormFields;
  prevFormValues: MutableRefObject<FormFields>;
};

type UseCreateAccountForm = () => UseCreateAccountFormExpected;

export const useCreateAccountForm: UseCreateAccountForm = () => {
  const {
    state: {
      profile: { profile },
    },
  } = useContext(StoreContext);

  const initialValues: FormFields = useMemo(
    () => ({
      [FormField.FirstName]: profile.firstName ?? '',
      [FormField.LastName]: profile.lastName ?? '',
      [FormField.Email]: profile.email ?? '',
      [FormField.Phone]: profile.phone ?? '',

      [FormField.PrivacyPolicyAccepted]: profile.privacyPolicyAccepted ?? false,
      [FormField.NewsletterConsent]: profile.newsletterConsent ?? false,
      [FormField.NewsSurveysConsent]: profile.newsSurveysConsent ?? false,
      [FormField.PartnersOffersConsent]: profile.partnersOffersConsent ?? false,
    }),
    [profile]
  );

  const prevFormValues = useRef<FormFields>(initialValues);

  return {
    prevFormValues,
    initialValues,
  };
};
