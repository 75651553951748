import { FC, memo } from 'react';
import Button from '@material-ui/core/Button';

import { Text, Content } from '../auth.styles';

type Props = {
  onClose: () => void;
};

export const AuthError: FC<Props> = memo(({ onClose }) => (
  <>
    <Content $marginBottom={40}>
      <Text variant="body2">Please try again later</Text>
    </Content>
    <Button color="primary" variant="contained" onClick={onClose}>
      Back to site
    </Button>
  </>
));
