import { FC, memo, useContext } from 'react';
import NextImage from 'next/image';
import Button from '@material-ui/core/Button';

import { StoreContext } from '@keaze/web/store';
import { Text, Content } from '../auth.styles';
import { Icon, Email, CheckEmail } from './success.styles';
import { AuthSuccessButtonProps } from './success.types';

type Props = {
  title: string;
  ButtonProps: AuthSuccessButtonProps;
  onClose: () => void;
};

export const AuthSuccess: FC<Props> = memo(
  ({
    title,
    ButtonProps: { title: buttonTitle, ...restButtonProps },
    onClose,
  }) => {
    const {
      state: {
        profile: { profile },
      },
    } = useContext(StoreContext);

    return (
      <>
        <Content
          container
          direction="column"
          alignItems="center"
          $marginBottom={25}
        >
          <Icon>
            <NextImage src="/svg/mail.svg" alt="mail" width={48} height={47} />
          </Icon>
          <CheckEmail component="span">{title}</CheckEmail>
          <Text variant="body2">
            You can sign in using the magic link that we have sent to{' '}
            <Email variant="body2" component="span">
              {profile.email ?? ''}
            </Email>
          </Text>
        </Content>
        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
          {...restButtonProps}
        >
          {buttonTitle}
        </Button>
      </>
    );
  }
);
