import { useEffect } from 'react';
import { useField, useForm } from 'react-final-form';

const FIELDS = [
  'newsletterConsent',
  'newsSurveysConsent',
  'partnersOffersConsent',
];

export const useIWantToReceiveFields = () => {
  const {
    input: { value: privacyPolicyAcceptedValue },
  } = useField('privacyPolicyAccepted');
  const form = useForm();

  useEffect(() => {
    if (privacyPolicyAcceptedValue) {
      FIELDS.map((field) => form.change(field, true));
    }
  }, [privacyPolicyAcceptedValue, form]);
};
