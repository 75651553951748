import { FC, memo } from 'react';
import { Form as FForm } from 'react-final-form';

import { RenderForm } from './renderForm/renderForm';
import { useSignInForm } from './form.hook';
import { FormFields } from './form.types';
import { RequestErrors } from '@keaze/web/common/interfaces';
import { OnSubmit } from '../signIn.types';
import { Form as UIForm } from './form.styles';
import { Loader } from '@keaze/web/components/Loader';
import { formValidate } from '@keaze/web/utils/form/utils';

type Props = {
  isLoading: boolean;
  errors: RequestErrors;
  onSubmit: OnSubmit;
};

export const Form: FC<Props> = memo(({ isLoading, errors, onSubmit }) => {
  const { initialValues, prevFormValues } = useSignInForm();

  return (
    <UIForm>
      <Loader open={isLoading} />
      <FForm<FormFields>
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(values) =>
          formValidate<FormFields>({
            values,
            prevFormValues,
            errors,
          })
        }
        render={(props) => <RenderForm {...props} />}
      />
    </UIForm>
  );
});
