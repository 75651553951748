import React, { FC, memo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormRenderProps } from 'react-final-form';

import { FormFieldRefs } from '@keaze/web/utils/form';
import { Groups } from '@keaze/web/utils/form/groups';
import { FormFields } from '../form.types';
import { Button, Container, IWantToReceive } from './renderForm.styles';
import { useRenderForm } from './renderForm.hook';
import {
  useScrollToField,
  useScrollToSubmitButton,
} from '@keaze/web/utils/form/hooks';

export const renderIWantToReceiveNotice = () => (
  <IWantToReceive variant="caption">
    You can update your preferences in your profile at any time
  </IWantToReceive>
);

export const RenderForm: FC<
  FormRenderProps<FormFields, Partial<FormFields>>
> = memo(({ handleSubmit }) => {
  const [fieldRefs, setFieldRefs] = useState<FormFieldRefs>({});
  const { fieldsGroups } = useRenderForm();
  const { scrollToField } = useScrollToField(fieldRefs);
  const { submitButtonRef, submitButtonActionRef } = useScrollToSubmitButton();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Container container>
        <Grid item xs={12}>
          <Groups
            fieldsGroups={fieldsGroups}
            getFieldRefs={(refs) => setFieldRefs(refs)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            action={submitButtonActionRef}
            ref={submitButtonRef}
            variant="contained"
            fullWidth
            type="submit"
            onClick={() => scrollToField()}
            color="secondary"
          >
            Create account
          </Button>
        </Grid>
      </Container>
    </form>
  );
});
