import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useCheckCustomerEmail } from '@keaze/web/hooks/queries/customers/useCheckCustomerEmail';
import { FormField, FormFields } from './form';
import { RequestErrors } from '@keaze/web/common/interfaces';
import { OnSubmit } from './createAccount.types';
import { useMagicLink } from '../hooks';
import {
  PROFILE_INITIAL_STATE,
  setProfile,
  StoreContext,
} from '@keaze/web/store';
import { GTMSignUp } from '@keaze/web/libs/GTM';
import { AuthContext } from '@keaze/web/contexts/auth';

const USER_EXIST_MESSAGE =
  'User with this email already exists. Try to Sign in.';

const DEFAULT_ERRORS = {};

type ExpectedUseCreateAccount = {
  isLoading: boolean;
  isMagicLinkSuccess: boolean;
  errors: RequestErrors;
  onClose: () => void;
  onSubmit: OnSubmit;
};

type UseCreateAccount = () => ExpectedUseCreateAccount;

type ExpectedGenerateContactPreferences = {
  [FormField.PrivacyPolicyAccepted]: boolean | null;
  [FormField.NewsletterConsent]: boolean | null;
  [FormField.NewsSurveysConsent]: boolean | null;
  [FormField.PartnersOffersConsent]: boolean | null;
};

type GenerateContactPreferences = (
  formValues: FormFields
) => ExpectedGenerateContactPreferences;

const generateContactPreferences: GenerateContactPreferences = (
  formValues
) => ({
  [FormField.PrivacyPolicyAccepted]:
    formValues[FormField.PrivacyPolicyAccepted],
  [FormField.NewsletterConsent]: formValues[FormField.NewsletterConsent],
  [FormField.NewsSurveysConsent]: formValues[FormField.NewsSurveysConsent],
  [FormField.PartnersOffersConsent]:
    formValues[FormField.PartnersOffersConsent],
});

export const useCreateAccount: UseCreateAccount = () => {
  const formData = useRef<FormFields | null>(null);
  const [errors, setErrors] = useState<RequestErrors>(DEFAULT_ERRORS);
  const { toggleAuthDialog } = useContext(AuthContext);
  const { dispatch } = useContext(StoreContext);
  const {
    mutate: checkCustomerEmailMutate,
    isLoading: isCheckCustomerEmailLoading,
    isSuccess: isCheckCustomerEmailSuccess,
    isError: isCheckCustomerEmailError,
  } = useCheckCustomerEmail();
  const {
    isLoading: isMagicLinkLoading,
    isSuccess: isMagicLinkSuccess,
    sendMagicLinkRegister,
  } = useMagicLink();

  // The user doesn't exist in the database
  useEffect(() => {
    if (isCheckCustomerEmailError && formData.current !== null) {
      sendMagicLinkRegister(formData.current);
      GTMSignUp(generateContactPreferences(formData.current));
    }
  }, [formData, isCheckCustomerEmailError, sendMagicLinkRegister]);

  useEffect(() => {
    if (isCheckCustomerEmailSuccess) {
      setErrors({
        [FormField.Email]: [USER_EXIST_MESSAGE],
      });
    }
  }, [isCheckCustomerEmailSuccess]);

  const handleClose = useCallback(() => {
    toggleAuthDialog();
  }, [toggleAuthDialog]);

  const handleSubmit: OnSubmit = useCallback(
    (values) => {
      formData.current = {
        ...formData.current,
        ...values,
      };

      dispatch(
        setProfile({
          ...PROFILE_INITIAL_STATE.profile,
          ...values,
        })
      );

      setErrors(DEFAULT_ERRORS);
      checkCustomerEmailMutate({ email: values.email });
    },
    [dispatch, checkCustomerEmailMutate]
  );

  return {
    isLoading: isCheckCustomerEmailLoading || isMagicLinkLoading,
    isMagicLinkSuccess,
    errors,
    onClose: handleClose,
    onSubmit: handleSubmit,
  };
};
