import NextLink from 'next/link';
import Grid from '@material-ui/core/Grid';

import { PrivacyPolicyLabel, PrivacyPolicyLink } from './privacyPolicy.styles';

export const renderPrivacyPolicy = () => (
  <Grid container alignItems="center">
    <PrivacyPolicyLabel variant="body2">I agree to the</PrivacyPolicyLabel>
    <PrivacyPolicyLink variant="body2" color="primary">
      <NextLink href="/privacy" prefetch={false} passHref>
        <a target="_blank">Keaze privacy policy</a>
      </NextLink>
    </PrivacyPolicyLink>
    <PrivacyPolicyLabel variant="body2">and</PrivacyPolicyLabel>
    <PrivacyPolicyLink variant="body2" color="primary">
      <NextLink href="/terms-of-use" prefetch={false} passHref>
        <a target="_blank">Terms of use</a>
      </NextLink>
    </PrivacyPolicyLink>
  </Grid>
);
