import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const Choice = styled(Box)`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
