import { useCallback, useContext, useEffect, useState } from 'react';

import { useCheckCustomerEmail } from '@keaze/web/hooks/queries/customers/useCheckCustomerEmail';
import { FormField } from './form';
import { RequestErrors } from '@keaze/web/common/interfaces';
import { useMagicLink } from '../hooks';
import { OnSubmit } from './signIn.types';
import {
  PROFILE_INITIAL_STATE,
  setProfile,
  StoreContext,
} from '@keaze/web/store';
import { AuthContext } from '@keaze/web/contexts/auth';

const USER_DOES_NOT_EXIST_MESSAGE = `We couldn't find a user with this email address.`;

const DEFAULT_ERRORS = {};

type ExpectedUseSignIn = {
  isLoading: boolean;
  isMagicLinkSuccess: boolean;
  errors: RequestErrors;
  onClose: () => void;
  onSubmit: OnSubmit;
};

type UseSignIn = (urlAfterSignIn?: string) => ExpectedUseSignIn;

export const useSignIn: UseSignIn = (urlAfterSignIn) => {
  const [errors, setErrors] = useState<RequestErrors>(DEFAULT_ERRORS);
  const {
    state: {
      profile: { profile },
    },
    dispatch,
  } = useContext(StoreContext);
  const { toggleAuthDialog } = useContext(AuthContext);
  const {
    mutate: checkCustomerEmailMutate,
    isLoading: isCheckCustomerEmailLoading,
    isSuccess: isCheckCustomerEmailSuccess,
    isError: isCheckCustomerEmailError,
  } = useCheckCustomerEmail();
  const {
    isLoading: isMagicLinkLoading,
    isSuccess: isMagicLinkSuccess,
    sendMagicLinkAccess,
  } = useMagicLink(urlAfterSignIn);

  // The user doesn't exist in the database
  useEffect(() => {
    if (isCheckCustomerEmailError) {
      setErrors({
        [FormField.Email]: [USER_DOES_NOT_EXIST_MESSAGE],
      });
    }
  }, [isCheckCustomerEmailError]);

  useEffect(() => {
    if (isCheckCustomerEmailSuccess) {
      sendMagicLinkAccess(profile.email ?? '');
    }
  }, [isCheckCustomerEmailSuccess, profile, sendMagicLinkAccess]);

  const handleClose = useCallback(() => {
    toggleAuthDialog();
  }, [toggleAuthDialog]);

  const handleSubmit: OnSubmit = useCallback(
    (values) => {
      dispatch(
        setProfile({
          ...PROFILE_INITIAL_STATE.profile,
          email: values[FormField.Email],
        })
      );

      setErrors(DEFAULT_ERRORS);
      checkCustomerEmailMutate({ email: values[FormField.Email] });
    },
    [checkCustomerEmailMutate, dispatch]
  );

  return {
    isLoading: isCheckCustomerEmailLoading || isMagicLinkLoading,
    isMagicLinkSuccess,
    errors,
    onClose: handleClose,
    onSubmit: handleSubmit,
  };
};
