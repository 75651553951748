import { useContext, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import { AuthContext } from '@keaze/web/contexts/auth';
import { QueryParams } from '@keaze/web/common/enums';
import { deleteParamFromQueryByName } from '@keaze/web/utils/deleteParamFromQueryByName';

type UseAuthExpected = {
  isSignInDialog: boolean;
  isAuthFailed: boolean;
  openAuthDialog: boolean;
  resetSignInDialog: () => void;
  onAuthErrorExited: () => void;
  onClose: () => void;
};

type UseAuth = () => UseAuthExpected;

export const useAuth: UseAuth = () => {
  const router = useRouter();
  const isAuthFailed = !!router.query[QueryParams.AuthFailed];
  const {
    isSignInDialog,
    openAuthDialog,
    toggleAuthDialog,
    resetSignInDialog,
  } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthFailed) {
      toggleAuthDialog();
    }
  }, [isAuthFailed, toggleAuthDialog]);

  const handleClose = useCallback(() => {
    toggleAuthDialog();
  }, [toggleAuthDialog]);

  const handleAuthErrorExited = useCallback(() => {
    deleteParamFromQueryByName({
      router,
      name: QueryParams.AuthFailed,
      withShallow: true,
    });
  }, [router]);

  return {
    isSignInDialog,
    isAuthFailed,
    openAuthDialog,
    resetSignInDialog,
    onAuthErrorExited: handleAuthErrorExited,
    onClose: handleClose,
  };
};
