import { useMutation } from 'react-query';

import { checkCustomerEmail } from '@keaze/web/api/customers/checkCustomerEmail';

type UseCheckCustomerEmailArgs = {
  email: string;
};

export const useCheckCustomerEmail = () =>
  useMutation<void, Error, UseCheckCustomerEmailArgs>(({ email }) =>
    checkCustomerEmail(email)
  );
