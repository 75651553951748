import { useEffect, useRef } from 'react';
import { useField } from 'react-final-form';
import { ButtonBaseActions } from '@material-ui/core/ButtonBase';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles';

import {
  SubmitButtonActionRef,
  SubmitButtonRef,
} from './useScrollToSubmitButton.types';

type ExpectedUseScrollToSubmitButton = {
  submitButtonRef: SubmitButtonRef;
  submitButtonActionRef: SubmitButtonActionRef;
};

type UseScrollToSubmitButton = () => ExpectedUseScrollToSubmitButton;

export const useScrollToSubmitButton: UseScrollToSubmitButton = () => {
  const isDownMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const submitButtonActionRef = useRef<ButtonBaseActions>(null);
  const {
    input: { value: privacyPolicyAcceptedValue },
  } = useField('privacyPolicyAccepted');

  useEffect(() => {
    const submitButton = submitButtonRef.current;
    const submitButtonAction = submitButtonActionRef.current;

    if (
      privacyPolicyAcceptedValue &&
      submitButton &&
      submitButtonAction &&
      isDownMedium
    ) {
      submitButtonAction.focusVisible();
      submitButton.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [privacyPolicyAcceptedValue, isDownMedium]);

  return {
    submitButtonRef,
    submitButtonActionRef,
  };
};
