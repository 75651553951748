import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const PrivacyPolicyLabel = styled(Typography)`
  margin-right: 4px;
`;

export const PrivacyPolicyLink = styled(Typography)`
  font-weight: 700;
  margin-right: 4px;
`;
