export enum FormField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phone',

  PrivacyPolicyAccepted = 'privacyPolicyAccepted',
  NewsletterConsent = 'newsletterConsent',
  NewsSurveysConsent = 'newsSurveysConsent',
  PartnersOffersConsent = 'partnersOffersConsent',
}

export type FormFields = {
  [FormField.FirstName]: string;
  [FormField.LastName]: string;
  [FormField.Email]: string;
  [FormField.Phone]: string;

  [FormField.PrivacyPolicyAccepted]: boolean | null;
  [FormField.NewsletterConsent]: boolean | null;
  [FormField.NewsSurveysConsent]: boolean | null;
  [FormField.PartnersOffersConsent]: boolean | null;
};
